import { useCallback } from 'react'
import useSWR from 'swr'
import { NewBackEndApiResponseData } from 'types/ApiResponseData'
import { UserFolder } from 'types/Folder'
import { apiClient } from 'utils/apiClient'
import { withApiErrorHandled } from 'utils/common'

const useFetchingMyFavoriteMoodboard = () => {
  const swrKey = '/v2/me/moodboards/favorites'
  const fetcher = useCallback(async () => {
    const response = await withApiErrorHandled(apiClient.get)(swrKey)
    const { data: collection } = response.data as NewBackEndApiResponseData<UserFolder>
    return collection
  }, [swrKey])

  const swrResponse = useSWR(swrKey, fetcher, { revalidateOnFocus: false })
  return swrResponse
}

export default useFetchingMyFavoriteMoodboard
