import cn from 'classnames'
import AvatarGroup from 'shared/components/avatar/AvatarGroup'
import RoundedAvatarImage from 'shared/components/avatar/RoundedAvatarImage'
import CustomImage from 'shared/components/CustomImage'
import Link from 'next/link'
import { useMemo } from 'react'
import { FolderThumbnailType } from 'types/V3/Folder'
import { getCohartImageUrl } from 'utils/artwork'
import NameWithVerificationBadge from '../display/NameWithVerificationBadge'
import { LockClosedIcon } from '@heroicons/react/24/outline'

type FolderThumbnailProps = {
  className?: string
  folder: FolderThumbnailType
  openLinkInNewTab?: boolean
  avatarClasses?: string
  folderNameClasses?: string
  userNameSize?: 'xs' | 'sm' | 'base' | 'md' | 'lg' | 'xl' | '2xl'
  featuringNameClasses?: string
  isPrivate?: boolean
}

const FolderThumbnail = (props: FolderThumbnailProps) => {
  const { className, folder, openLinkInNewTab, avatarClasses, folderNameClasses, featuringNameClasses } = props

  const { fullName: ownerName, username: ownerUsername, coverImage: ownerAvatar, isVerified: isVerified } = folder.user
  const {
    fullName: folderCollaboratorName,
    coverImage: collaboratorAvatar,
    isVerified: collaboratorIsVerified,
  } = folder.collaborators?.[0] || {}

  const folderName = useMemo(() => {
    if (folder.type === 'purchased') return 'Purchased Artworks'
    if (folder.type === 'favorites') return 'Private Moodboard' // update from 'Favorites' to 'Private Moodboard'
    return folder.name
  }, [folder.name, folder.type])

  const isPrivateMoodboard = folder.type === 'favorites'

  const folderLink = (() => {
    if (folder.type === 'purchased') return `/u/${ownerUsername}/folders/purchased-artworks`
    if (folder.type === 'favorites') return '/private-moodboard'
    return `/u/${ownerUsername}/moodboards/${folder.id}` // link to moodboard
  })()

  const { frontCoverImage, backCoverImage } = useMemo(() => {
    const frontCoverImage = getCohartImageUrl(folder.thumbnails?.[1] || {}, 'imageMedium', '')
    const backCoverImage = getCohartImageUrl(folder.thumbnails?.[0] || {}, 'imageMedium', '')
    return { frontCoverImage, backCoverImage }
  }, [folder.thumbnails])

  const coverFeaturingArtist = useMemo(() => {
    const coverArtist = folder.contentAuthors[0] || null
    return coverArtist
  }, [folder.contentAuthors])

  return (
    <Link
      passHref
      href={folderLink}
      className={cn(
        'block aspect-3/4 cursor-pointer overflow-hidden rounded-[11px] border border-black/10 shadow hover:shadow-lg ',
        className,
      )}
      target={openLinkInNewTab ? '_blank' : undefined}
    >
      <div className="relative h-full w-full">
        {/* back image */}
        <div className="absolute bottom-[40%] left-1/2 aspect-[4/5] h-2/3 origin-center translate-x-[-20%] translate-y-[-20%] rotate-[9deg]">
          <div className="relative h-full w-full overflow-hidden rounded-[10px] border border-black/10 bg-gray-200 shadow">
            {frontCoverImage && (
              <CustomImage
                src={frontCoverImage}
                placeholder="blur"
                blurDataURL="/images/placeholder.webp"
                fill
                className="object-cover object-center"
              />
            )}
          </div>
        </div>

        {/* front image */}
        <div className="absolute bottom-[40%] left-1/2 aspect-[4/5] h-2/3 origin-center rotate-[15deg]">
          <div className="relative h-full w-full overflow-hidden rounded-[10px] border border-black/10 bg-white shadow-lg">
            {backCoverImage && (
              <CustomImage
                src={backCoverImage}
                placeholder="blur"
                blurDataURL="/images/placeholder.webp"
                fill
                className="object-cover object-center"
              />
            )}
          </div>
        </div>

        {/* only private moodboard have the label */}
        {isPrivateMoodboard && (
          <div
            className={cn(
              'absolute left-4 top-4 z-10 rounded-full px-[14px] py-[7px]',
              'text-[10px] font-medium leading-[12px] tracking-wide text-white',
              'border border-black/10 bg-kokushoku-black',
              'inline-flex items-center',
            )}
          >
            <LockClosedIcon className="size-[12px]" />
            <span className="uppercase">&nbsp;Private</span>
          </div>
        )}

        {/* moodboard's other info */}
        <div className="absolute bottom-0 left-0 z-10 w-full p-2 text-black lg:p-4">
          <div className="flex w-full flex-col gap-1 lg:gap-2">
            {/* collaborator avatars */}
            <AvatarGroup>
              <RoundedAvatarImage
                className={cn('h-[25px] w-[25px] lg:h-[30px] lg:w-[30px]', avatarClasses)}
                alt="artist name"
                src={ownerAvatar}
              />
              {folderCollaboratorName && (
                <RoundedAvatarImage
                  className={cn('h-[25px] w-[25px] lg:h-[30px] lg:w-[30px]', avatarClasses)}
                  alt="artist name"
                  src={collaboratorAvatar}
                />
              )}
            </AvatarGroup>

            {/* moodboard name */}
            <div className={cn('line-clamp-2 text-ellipsis break-words text-base font-semibold', folderNameClasses)}>
              {folderName}
            </div>

            {/* owner name */}
            <div className="text-xs">
              <div className="inline">
                <span>by&nbsp;</span>
                <NameWithVerificationBadge
                  fullName={ownerName}
                  isVerified={isVerified}
                  className="inlin justify-start text-xs font-medium"
                  maxNameLength={-1}
                />
              </div>

              {/* collaborator name */}
              {folderCollaboratorName && (
                <div className="inline">
                  <span>&nbsp;and&nbsp;</span>
                  <NameWithVerificationBadge
                    fullName={folderCollaboratorName}
                    isVerified={collaboratorIsVerified}
                    className="justify-start text-xs font-medium"
                    maxNameLength={-1}
                  />
                </div>
              )}
            </div>

            {/* featured artists */}
            {coverFeaturingArtist && (
              <div
                className={cn(
                  'inline-flex w-full items-center text-[10px] font-medium text-black/40',
                  featuringNameClasses,
                )}
              >
                Featuring <strong className="truncate">&nbsp;{coverFeaturingArtist.fullName}</strong>
                {folder.contentAuthorsOtherCounts > 0 && (
                  <span className="flex-1 text-left">
                    &nbsp;+{folder.contentAuthorsOtherCounts}&nbsp;
                    <strong>{folder.contentAuthorsOtherCounts === 1 ? 'other' : 'others'}</strong>
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default FolderThumbnail
