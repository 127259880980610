import { useCallback, useRef } from 'react'

const useIgnoreSwrCachingFistPage = () => {
  const cacheKeyRef = useRef(new Date().getTime())
  // This is the key to ignore caching the first page,
  // for other pages, it always be 0, so we have the benefit of caching
  // use this to gen a random prop to provide SWR key
  // when you got issue with showing list of items on a Masonry layout
  // since the Masonry layout has its own cache, and can be conflict with SWR cache
  const randomProp = useCallback((pageIndex: number) => {
    const randomProp = pageIndex === 0 ? cacheKeyRef.current : 0
    return randomProp
  }, [])

  return randomProp
}

export default useIgnoreSwrCachingFistPage
