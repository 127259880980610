import classNames from 'classnames'
import Skeleton from 'shared/components/loader/Skeleton'
import useFetchingMoodboardFolders from 'hooks/user/useFetchingMoodboardFolders'
import flattenDeep from 'lodash.flattendeep'
import { memo, useCallback } from 'react'
import ScrollWrapper from 'shared/components/layout/ScrollWrapper'
import FolderThumbnail from 'shared/components/thumbnails/FolderThumbnail'
import { UserFolder } from 'types/Folder'
import ProfileSectionWrapper from './ProfileSectionWrapper'
import useFetchingMyFavoriteMoodboard from 'shared/hooks/moodboard/useFetchingMyFavoriteMoodboard'

const StyledSkeleton = () => <Skeleton className="aspect-3/4 min-w-[167px] lg:min-w-[200px]" variant="rectangular" />

type ProfileMomentsCarouselProps = {
  username: string
  isCurrentUser: boolean
}

const ProfileMoodboardsSection = (props: ProfileMomentsCarouselProps) => {
  const { username, isCurrentUser } = props
  const { data: moodboardPages, isEmpty: isMoodboardsEmpty, isLoading } = useFetchingMoodboardFolders(username)

  const moodboards = moodboardPages ? flattenDeep(moodboardPages) : []
  const renderMoodboardItem = useCallback((moodboard: UserFolder) => {
    const key = `${moodboard.type}-${moodboard.id || 0}`
    return (
      <div key={key} className="w-[167px] lg:w-[200px]">
        <FolderThumbnail folder={moodboard} />
      </div>
    )
  }, [])

  if (isMoodboardsEmpty && !isCurrentUser) return null
  const seeAllLink = isMoodboardsEmpty ? undefined : `/u/${username}/moodboards`
  return (
    <ProfileSectionWrapper
      title="Moodboards"
      seeAllLink={seeAllLink}
      editLink={isCurrentUser ? '/u/moodboards/create' : undefined}
      editText={isCurrentUser ? '+ Create' : undefined}
    >
      <div className="relative flex h-full min-h-[250px] w-full items-center lg:min-h-[320px]">
        {/* loading state */}
        {isLoading && (
          <div className="flex h-full w-full gap-2 lg:gap-4">
            <StyledSkeleton />
            <StyledSkeleton />
            <StyledSkeleton />
            <StyledSkeleton />
            <StyledSkeleton />
            <StyledSkeleton />
          </div>
        )}

        {!isLoading && (
          <ScrollWrapper autoHide={false} wrapperClassName="full-width-scroll-wrapper w-full">
            <div
              className={classNames(
                'inline-flex items-stretch gap-2',
                'px-1 pb-6 pt-1', // for scrollbar
                'lg:gap-4',
              )}
            >
              {isCurrentUser && <PrivateMoodboardThumbnail />}
              {moodboards?.map(renderMoodboardItem)}
            </div>
          </ScrollWrapper>
        )}
      </div>
    </ProfileSectionWrapper>
  )
}

const PrivateMoodboardThumbnail = () => {
  const { data: favoriteMoodboard } = useFetchingMyFavoriteMoodboard()
  if (!favoriteMoodboard) return null
  return (
    <div key="private-moodboard" className="w-[167px] lg:w-[200px]">
      <FolderThumbnail folder={favoriteMoodboard} />
    </div>
  )
}

export default memo(ProfileMoodboardsSection)
