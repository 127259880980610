import useSWRInfiniteHelper from 'hooks/swr/useSWRInfiniteHelper'
import useIgnoreSwrCachingFistPage from 'shared/hooks/other/useIgnoreSwrCachingFistPage'
import useSWRInfinite from 'swr/infinite'
import { NewBackEndApiResponseData } from 'types/ApiResponseData'
import { UserFolder } from 'types/Folder'
import { apiClient } from 'utils/apiClient'
import { withApiErrorHandled } from 'utils/common'

const useFetchingMoodboardFolders = (username: string, pageSize = 10) => {
  const randomProp = useIgnoreSwrCachingFistPage()
  const swrResponse = useSWRInfinite(
    (pageIndex) => {
      const params = `page=${pageIndex + 1}&pageSize=${pageSize}&username=${username}&random=${randomProp(pageIndex)}`
      const swrKey = `/v2/public/users/moodboards?${params}`
      return swrKey
    },
    async (swrKey: string) => {
      const response = await withApiErrorHandled(apiClient.get)(swrKey)
      const { data } = response.data as NewBackEndApiResponseData<{ list: UserFolder[] }>
      return data.list
    },
    { revalidateOnFocus: false },
  )

  const { data, size, setSize, isValidating, isLoading } = swrResponse
  const { isEmpty, isLoadingFirstPage, isLoadingMore, loadMore, isReachingEnd } = useSWRInfiniteHelper(swrResponse, {
    pageSize,
  })

  return {
    data,
    isLoadingInitialData: isLoadingFirstPage,
    isLoadingMore,
    isEmpty,
    isReachingEnd,
    isValidating,
    isLoading,
    size,
    setSize,
    onLoadMore: loadMore,
  }
}

export default useFetchingMoodboardFolders
